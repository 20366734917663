<template>
	<!-- 免费领地活动编辑 -->
	<div class="adhibition-freeland-edit el-content">
		<a-form :label-col="{ span: 2 }" :wrapper-col="{ span: 8 }">
			<a-form-item label="选择地块">
				<a-select v-model:value="event.land_id" placeholder="请选择地块">
					<a-select-option v-for="(item,index) in landData" 
						:key="index" 
						:value="item.id">{{item.land_name}}
					</a-select-option>
				</a-select>
			</a-form-item>
			<a-form-item label="活动开启">
				<kd-switch :value="event.land_give_open" @change="e=>event.land_give_open = e" :active-value="1" :inactive-value="0"></kd-switch>
			</a-form-item>
			<a-form-item label="标签文字">
				<a-input v-model:value="event.tag" placeholder="请输入标签文字,例如:免费领土地"></a-input>
			</a-form-item>
			<a-form-item label="土地免费期限">
				<a-input v-model:value="event.land_give_receive_day" addon-after="天" placeholder="请输入土地免费期限" type="number">
				</a-input>
			</a-form-item>
			<a-form-item>
				<template #label>
					<span>激活有效期</span>
					<a-tooltip title="在有效期内成功下种植订单,则视为激活成功">
						<i class="ri-information-fill"></i>
					</a-tooltip>
				</template>
				<a-input v-model:value="event.land_give_active_day" addon-after="天" placeholder="请输入激活有效期" type="number">
				</a-input>
			</a-form-item>
			<a-form-item>
				<template #label>
					<span>可重复领取次数</span>
					<a-tooltip title="满足条件的用户可以领取免费地块的次数,0天表示不限制">
						<i class="ri-information-fill"></i>
					</a-tooltip>
				</template>
				<a-input v-model:value="event.land_give_claim_times"  addon-after="次" placeholder="请输入限制领取次数" type="number">
				</a-input>
			</a-form-item>
			<a-form-item>
				<template #label>
					<span>消费金额</span>
					<a-tooltip content="开启后,则用户满足消费X元金额后才可领取">
						<i class="ri-information-fill"></i>
					</a-tooltip>
				</template>
				<kd-switch :value="event.land_give_consumption_amount_is_open" @change="e=>event.land_give_consumption_amount_is_open = e" :active-value="1" :inactive-value="0"></kd-switch>
				<a-input v-model:value="event.land_give_consumption_amount"
					placeholder="请输入消费金额" 
					type="number" 
					style="width: 300px;"
					addon-after="元可领取" 
					addon-before="消费满">
				</a-input>
			</a-form-item>
			<a-form-item label="连续签到">
				<template #label>
					<span>连续签到</span>
					<a-tooltip title="开启后,则用户连续签到X天的用户可领取">
						<i class="ri-information-fill"></i>
					</a-tooltip>
				</template>
				<kd-switch :value="event.land_give_continuous_sign_in_is_open" @change="e=>event.land_give_continuous_sign_in_is_open = e" :active-value="1" :inactive-value="0"></kd-switch>
				<a-input v-model:value="event.land_give_continuous_sign_in" 
					placeholder="请输入消费金额" 
					type="number" 
					style="width: 300px;" addon-after="天可领取" addon-before="连续签到">
				</a-input>
			</a-form-item>
			<a-form-item>
				<template #label>
					<span>新用户领取</span>
					<a-tooltip title="满足新用户条件的用户才可领取">
						<i class="ri-information-fill"></i>
					</a-tooltip>
				</template>
				<kd-switch :value="event.land_give_newuser_is_open" @change="e=>event.land_give_newuser_is_open = e" :active-value="1" :inactive-value="0"></kd-switch>
			</a-form-item>
			<a-form-item>
				<template #label>
					<span>领取区域</span>
					<a-tooltip title="开启后,则用户在指定区域才能领取">
						<i class="ri-information-fill"></i>
					</a-tooltip>
				</template>
				<kd-switch :value="event.land_give_area_is_open" @change="e=>event.land_give_area_is_open = e" :active-value="1" :inactive-value="0"></kd-switch>
			</a-form-item>
			<a-form-item label="选择区域" v-if="event.land_give_area_is_open == 1">
				<div v-for="(item,index) in event.land_give_area" :key="index">
					经纬度：{{item.lnglat}},半径：{{item.radius}}米 
					<a-button type="link" @click="editMap(item,index,'edit')">[编辑]</a-button>
					<a-button type="link" @click="editMap(item,index,'del')">[删除]</a-button>
				</div>
				<a-button type="link" @click="drawMap"><i class="ri-add-line"></i>添加区域</a-button>
			</a-form-item>
			<a-form-item label="上下架状态">
				<kd-switch :value="event.is_put_away" @change="e=>event.is_put_away = e" :active-value="1" :inactive-value="0" ></kd-switch>
			</a-form-item>
			<a-form-item :wrapper-col="{ offset: 2 }">
				<a-space>
					<a-button type="primary"
						@click="submitSave" 
						:loading="$store.state.submit">{{$store.state.submit?'正在保存...':'保存'}}
					</a-button>
					<a-button  @click="$router.go(-1)">返回</a-button>
				</a-space>
			</a-form-item>
		</a-form>
		<a-modal v-model:visible="show.map" title="选择区域" :footer="null" width="1000px">
			<kd-map-earth :map-height="600" draw-type="circle" :mapValue="mapForm.lnglat" @getMapData="getMapData"></kd-map-earth>
		</a-modal>
	</div>
</template>

<script>
import kdMapEarth from "@/components/public/kd-map-earth.vue"
import { reactive, toRefs } from 'vue'
import { useRoute } from 'vue-router'
import freeLandModel from '@/api/addons/freeLand'
import landModel from '@/api/land'
export default{
	components:{
		kdMapEarth
	},
	setup(){
		const state = reactive({
			show:{
				map:false,
				map_type:'city',  //区域选择类型 city = 城市区域赠送 send = 配送区域
			},
			landData:[],
			event:{
				id:0,
				tag:"",
				land_id:undefined,			//土地id
				land_give_open:0,
				land_give_receive_day:"",
				land_give_active_day:"",
				land_give_claim_times:"",
				land_give_consumption_amount:"",
				land_give_consumption_amount_is_open:0,
				land_give_continuous_sign_in:"",
				land_give_continuous_sign_in_is_open:0,
				land_give_area:[],
				land_give_area_is_open:0,
				land_give_newuser_is_open:0,
				is_put_away:0,
			},
			mapForm:{
				lnglat:null,
				index:0
			}
		})

		const opt = useRoute().query

		landModel.getLandList(1,999,null,res=>state.landData = res.list)
		if( opt.id ){
			freeLandModel.getFreeLandDetail(opt.id,res=>{
				state.event = {
					id:res.id,
					tag:res.tag,
					land_id:res.land_id,
					is_put_away:res.is_put_away,
					land_give_open:res.land_give_open,
					land_give_area:res.land_give_area ? JSON.parse(res.land_give_area) :[],
					land_give_active_day:res.land_give_active_day,
					land_give_receive_day:res.land_give_receive_day,
					land_give_claim_times:res.land_give_claim_times,
					land_give_area_is_open:res.land_give_area_is_open,
					land_give_newuser_is_open:res.land_give_newuser_is_open,
					land_give_consumption_amount:res.land_give_consumption_amount,
					land_give_continuous_sign_in:res.land_give_continuous_sign_in,
					land_give_consumption_amount_is_open:res.land_give_consumption_amount_is_open,
					land_give_continuous_sign_in_is_open:res.land_give_continuous_sign_in_is_open,
				}
			})
		}

		function drawMap(){
			state.mapForm = {
				lnglat:null,
				index:0
			}
			state.show.map = true
		}

		function getMapData(e){
			if( state.mapForm.lnglat ){
				state.event.land_give_area[state.mapForm.index].lnglat = [e.center.lng,e.center.lat]
				state.event.land_give_area[state.mapForm.index].radius = e.radius
			}else{
				state.event.land_give_area.push({
					lnglat:[e.center.lng,e.center.lat],
					radius:e.radius
				})
			}
			state.mapForm = {
				lnglat:null,
				index:0
			}
			state.show.map = false
		}

		/**
		 * 编辑/删除地址信息
		 * @param {Object} data		经纬度信息
		 * @param {Number} index	经纬度索引
		 * @param {String} op_type	操作类型 op_type = 编辑(edit) 删除(del)
		 */
		function editMap(data,index,op_type){
			if( op_type == 'edit'){
				state.mapForm.lnglat = data
				state.mapForm.index= index
				state.show.map = true
			}
			
			if( op_type == 'edit'){
				state.mapForm.lnglat = data
				state.mapForm.index= index
				state.show.map = true
			}
			if( op_type == 'del'){
				state.event.land_give_area.splice(index,1)
			}
		}

		const submitSave = ()=>{
			let form = JSON.parse(JSON.stringify(state.event))
			freeLandModel.addOrEditFreeLand(form)
		}

		return{
			...toRefs(state),
			submitSave,
			drawMap,
			getMapData,
			editMap
		}
	}
}
</script>

<style>
</style>
